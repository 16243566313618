<template>
  <div class="titlesTop">
    <headTitle />
    <tree :list="serveList" :treetype="2"></tree>
  </div>
</template>

<script>
import peopleOrderApi from "@/api/peopleOrder";
import Tree from '../massorder/tree';
export default {
  components: {
    Tree
  },
  data() {
    return {
      serveList: []
    }
  },
  mounted() {
    this.getservertree()
  },
  methods: {
    //获取服务类型
    getservertree() {
      let params = {
        appName: this.appName
      }
      peopleOrderApi.servertree(params).then(res => {
        if (res.success) {
          this.serveList = res.data
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>

</style>